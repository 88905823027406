import React from "react";
import Layout from "../components/layout";
import { ExhibitionPreview } from "../utils/exhibition";

const Exhibition = ({ pageContext }) => {
  const { data: exhibitions } = pageContext;
  return (
    <Layout>
      <section className="container">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="title is-3">Exhibitions</p>
            </div>
          </div>
        </div>
        <div className="tile is-ancestor">
          {exhibitions.map((e) => (
            <ExhibitionPreview exhibition={e} key={e.uid} />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default Exhibition;
